/*************************** BACKGROUND COLORS **************************/
.bg-dark{
    background-color: $dark_bg;
}
.bg-grey{
    background-color: $grey;
}
.bg-light-grey{
    background-color: $light_grey;
}
.bg-dark-grey{
    background-color: $dark_grey;
}
.bg-darkest-grey{
    background-color: $dark_text;
}
.bg-lightest-grey{
    background-color: $light_text;
}
.bg-blue{
    background-color: $blue;
}
.bg-red{
    background-color: $red;
}
.bg-yellow{
    background-color: $yellow;
}
.bg-light-green{
    background-color: $light_green;
}
.bg-dark-green{
    background-color: $dark_green;
}
.bg-additional-grey{
    background-color: $additional_grey;
}
.bg-amt-grey{
    background-color: $amt_grey;
}
.bg-white-shade{
    background-color: $white_shade;
}

/*************************** TEXT COLORS **************************/
.text-darkest-grey{
    color: $darkest_grey !important;
}
.text-dark-grey{
    color: $dark_grey;
}
.text-dark{
    color: $dark_text !important;
}
.text-lightest{
    color: $light_text;
}
.text-blue{
    color: $blue;
}
.text-red{
    color: $red;
}
.text-yellow{
    color: $yellow;
}
.text-light-green{
    color: $light_green;
}
.text-dark-green{
    color: $dark_green;
}
.text-white-shade{
    color: $white_shade;
}
.text-pink{
    color: $pink;
}

/*************************** BORDERS **************************/

.border-grey{
    border: 1px solid $grey;
}
.border-left-grey{
    border-left: 1px solid $grey;
}
.border-right-grey{
    border-right: 1px solid $grey;
}
.border-top-grey{
    border-top: 1px solid $grey;
}
.border-bottom-grey{
    border-bottom: 1px solid $grey;
}
.border-additional-grey{
    border: 1px solid $additional_grey;
}

@include media-breakpoint-down(sm){
    .border-left-grey-sm-0{
        border-left: 0px !important;
    }
    .border-right-grey-sm-0{
        border-right: 0px !important;
    }
}
